<template>
  <div :class="[contentWidth === 'boxed' ? 'container p-0' : null,$route.meta.contentClass]">
    <router-view />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from 'vue-demi'

export default {
  setup() {
    
    const {
      resizeHandler,
    } = useVerticalLayout()

     const { contentWidth } = useAppConfig()

    resizeHandler()
    window.addEventListener('resize',resizeHandler)

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return { 
      contentWidth 
    }
  },
}
</script>
